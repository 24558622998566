import { Flex, Text, Image, Spinner } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import logo from "./logo.png"
import s1 from "./s1.png"
import s2 from "./s2.png"
import s3 from "./s3.png"
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import v1 from "./v1.png"
import v2 from "./v2.png"
import Privacy from "./privacy";
import { useHistory } from "react-router-dom";

export default function Landing() {
    const [showScreen, setShowScreen] = useState(false)
    const iniScreen = localStorage.getItem("iniScreen") !== null ? true : false
    const [rusel, setRusel] = useState(0)
    const [screen, setScreen] = useState(0)
    const [contract, setContract] = useState(false)
    const history = useHistory()

    useEffect(() => {
        setTimeout(function() {
            if(iniScreen) {
                setShowScreen("proceed")
            }
            else {
                setShowScreen(true)
            }
        }, 1500)
    }, [])

    useEffect(() => {
        if(rusel === 2) {
            var height = document.body.scrollHeight;
            window.scroll(0 , height)
        }
    }, [rusel])

    return (
        <Flex w="100%" h={["100vh", "100%"]}>
            { 
                showScreen === "proceed" ? history.push("/dashboard")
                :
                showScreen ?
                screen === 0 ?
                    <Flex w="100%" h="100%" justify="center" align="center" px="6" pb="90px" direction="column">
                        <Flex flex="1" justify="center" align="center" direction="column">
                            <Flex w="100%" direction="column" align="center" justify="center">
                                <Carousel
                                    interval={2000}
                                    autoPlay={true}
                                    infiniteLoop={false}
                                    showStatus={false}
                                    showIndicators={false}
                                    swipeable={true}
                                    showArrows={false}
                                    showThumbs={false}
                                    stopOnHover={false}
                                    onChange={(item) => {
                                        setRusel(item)
                                    }}
                                >
                                    <Flex justify="center" w="100%" bg="#fff"><Image src={s1} h="225px" maxWidth="210px" /></Flex>
                                    <Flex justify="center" w="100%" bg="#fff"><Image src={s2} h="225px" maxWidth="232px" /></Flex>
                                    <Flex justify="center" w="100%" bg="#fff"><Image src={s3} h="225px" maxWidth="234px" /></Flex>
                                </Carousel>
                            </Flex>

                            <Text mt="12">
                                <Text textAlign="center">{rusel === 0 ? "Multi-account & Multi-currency management" : rusel === 1 ? "Secure and fast token swaps" : "Real-time token quotations"}</Text>
                                <Text color="#007cff" textAlign="center" mt="3">{rusel === 0 ? "Asset management" : rusel === 1 ? "Safe transactions" : "Real-time markets"}</Text>
                            </Text>
                        </Flex>

                        <Flex mt="auto" justify="center" align="center">
                            {
                                [0,1,2].map((item, index) => (
                                    <Flex key={index} w="8px" h="8px" borderRadius="100%" bg={index === rusel ? "#007cff" : "rgb(180,180,180)"} mx={index === 1 && "4"}></Flex>
                                ))
                            }
                        </Flex>
                        {
                            rusel === 2 &&
                            <Flex padding="10px 50px" mt="3" mb="-55px" fontWeight="700" justify="center" align="center" color="#fff" bg="#007cff" borderRadius="30px" cursor="pointer" onClick={() => setScreen(1)}>Start</Flex>
                        }
                    </Flex>
                    :
                    <Flex w="100%" h="100%" direction="column">
                        <Flex fontSize="20px" justify="center" py={["4", "5"]} align="center" w="100%" position={["fixed", "relative"]} bg="#fff" zIndex="10000" top="0">ELLIPAL Terms and Conditions</Flex>

                        <Flex mt={["80px", "5px"]} flex="1" color="#767676" overflowY="scroll" py="12" borderRadius="15px 15px 0 0" boxShadow="0 -12px 6px -10px rgba(0, 124, 255, .15)" px="4" className="noscbar">
                            <Privacy />
                        </Flex>

                        <Flex pt="3" pb="7" direction="column" px="4">
                            <Flex w="100%" align="center">
                                <Image w="24px" h="24px" src={contract ? v2 : v1} cursor="pointer" onClick={() => contract ? setContract(false) : setContract(true)} />
                                <Text ml="3" color="#007cff" fontSize="13px">I have carefully read and agreed to the Service Agreement and Privacy Policy.</Text>
                            </Flex>
                            <Flex w="100%" padding="10px 50px" mt="3" fontWeight="700" justify="center" align="center" color="#fff" bg="#007cff" borderRadius="30px" cursor={contract ? "pointer" : "not-allowed"} onClick={() => {
                                if(contract) {
                                    localStorage.setItem("iniScreen", true)
                                    setShowScreen("proceed")
                                }
                            }} opacity={contract ? "1" : ".4"} transition="300ms ease-in-out">Confirm</Flex>
                        </Flex>
                    </Flex>
                :
                <Flex w="100%" h="100%" justify="center" align="center">
                    <Image src={logo} h="60px" mt="-10" />
                </Flex>
            }
        </Flex>
    )
}