import { Flex, Text, Image, Spinner } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import bk from "./bk.png"
import { useHistory } from "react-router-dom";


export default function What() {
    const history = useHistory()
    const mip = localStorage.getItem("mip")
    const fk = '{\"version\":3,\"id\":\"68db23d2-0508-4f88-bc92-bc57cc5ec629\",\"address\":\"de6915dbc1f77149255bebeb8cb76cb51d13fbc4\",\"Crypto\":{\"ciphertext\":\"08b84313d0b489cc342cd94bb7dc1d1b692c71c64f01dbe967b699cd4e8396a9\",\"cipherparams\":{\"iv\":\"1aa98c91026b4835f627067a02196c1e\"},\"cipher\":\"aes-128-ctr\",\"kdf\":\"scrypt\",\"kdfparams\":{\"dklen\":32,\"salt\":\"79090031bfd4eaec1bc42ac3a2929522bd030f16894963a4c9597e8f1ffd657e\",\"n\":8192,\"r\":8,\"p\":1},\"mac\":\"6b1ee5c73a208d9e4d481d0388ad4ea0e29d045172058e0da9822ca3b114452e\"}}'

    return (
        <Flex w="100%" h="100%" direction="column" data-aos="fade-up">
            <Flex px="5" py="5" align="center" w="100%">
                <Image src={bk} cursor="pointer" h="18px" onClick={() => history.goBack()} />
                <Flex justify="center" fontSize="20px" flex="1" pr="20px">{mip === "0" ? "What is a seed phrase?" : mip === "1" ? "What is a private key?" : "What is a Keystore?"}</Flex>
            </Flex>

            <Flex className="noscbar" flex="1" overflowY="scroll" py="8" borderRadius="15px 15px 0 0" boxShadow="0 -12px 6px -10px rgba(0, 124, 255, .15)" px="4" direction="column">
                <Text fontWeight="bold" pl="4" borderLeft="2px solid #007cff" fontSize="17px">What is a {mip === "0" ?"seed phrase" : mip === "1" ? "private key" : "Keystore"}?</Text>
                <Text ml="1">
                    {
                        mip === "0" ?
                        <>
                            <Text mb="4">
                            Seed phrase is a form of expression of the plaintext private key, which can be equated with the private key. The purpose is to facilitate user to memory and backup for the complex private key.</Text>
                            
                            <Text mb="4">
                            Seed phrase generally consist of 12 to 24 words,Seed phrase words are generally made up from 12 to 24 words,All of these words come from this thesaurus,which is derived from a certain algorithm.It is best way to copy the seed phrase words and even put them in the safety box.If you only took a screenshot or saved in the computer,as long as the device is connected to the Internet,there is a risk of being seen by a third part.</Text>


                            Once the seed phrase word is lost, it as same as the lost of private key, who knows your seed phrase word is the same as knowing the private key. It means that you are going to lose all the coins in your address and will never get it back.Be careful of everywhere you need to fill out your private key and seed phrase on the network.
                        </>
                        :
                        mip === "1" ?
                        <>
                            <Text mb="4">
                            The seed phrase is a method which is used to generate the private key. For the cryptocurrency wallet, the most fundamental thing is the private key. The asymmetric encryption algorithms uses cryptocurrencies contain both private and public keys. When you have the private keys, then you can control the wallet. Both Keystores and seed phrase are actually same as private keys. Without a private key, signature transactions cannot be completed.</Text>
                            <Text mb="4">
                            The formation of the private key is a 64-bit hexadecimal hash string, for example: 3c53d8de2a5c45694ab5008816fb1ba77f3a17b5ddb55be0c74a6534c4ef2568</Text>

                            Whether you use seed phrase or a Keystore, the corresponding private key will be recovered when the transaction signature is sent. You must keep the private key confidential, because once leaked to a third party, the assets under protection of the private key are defended. It is different from Keystore. Keystore is an encrypted private key file. If the password strength is strong enough, even if the hackers get the Keystore, the difficulty of cracking is high enough.
                        </>
                        :
                        <>
                            <Text mb="4">
                            The Keystore file format is in (JSON) for the wallet to store private keys. It uses user-defined password encryption to achieve a certain degree of protection, and the degree of protection depends on the strength of the user's password to encrypt the wallet.</Text>

                            <Text mb="4" fontWeight="bold">Notifications:There are two points to note when using the Keystore:</Text>

                            <Text mb="4">1. Encrypt the Keystore file with a password that is less common and as complex as possible; 2. Be sure to remember the password that was used to encrypt the Keystore. If you forget the password, you lose the right to use the Keystore, and you can not get the password back, so be sure to keep the Keystore and the password is safe eough for you.</Text>

                            <Text mb="4" fontWeight="bold">Here is the Keystore formation:</Text>

                            <Text mb="4">{fk}</Text>

                            <Text mb="4" fontWeight="bold">P.S.</Text>

                            Keystore is unique and unchangeable. To change the wallet password, you need to re-import the wallet using seed phrase or private keys, and encrypt it using the new password to generate a new Keystore.
                        </>
                    }
                </Text>
            </Flex>
        </Flex>
    )
}