import { Flex, Text, Image, Spinner } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import {
	BrowserRouter as Router,
	Switch,
	Route,
  } from "react-router-dom";
import ScrollToTop from "./sc";
import Screen1 from "./screen1";
import Nav from "./nav";
import Screen2 from "./screen2";
import Screen3 from "./screen3";
import What from "./what";
import Screen4 from "./screen4";
import Screen5 from "./screen5";
import Screen6 from "./screen6";
import Screen7 from "./screen7";
import Screen8 from "./screen8";
import Screen9 from "./screen9";
import Screen10 from "./screen10";
import Screen11 from "./screen11";


export default function Dashboard() {
    return (
        <Flex w="100%" h={["100vh", "100%"]} direction="column">
			<Router>				
				<ScrollToTop>
                    <Flex w="100%" flex="1" overflowY="scroll" className="noscbar">
                        <Switch>
                            <Route exact path="/dashboard"><Screen1 /></Route>
                            <Route path="/dashboard/import"><Screen2 /></Route>
                            <Route path="/dashboard/import-proceed"><Screen3 /></Route>
                            <Route path="/dashboard/about"><What /></Route>
                            <Route path="/dashboard/select"><Screen4 /></Route>
                            <Route path="/dashboard/private-key"><Screen5 /></Route>
                            <Route path="/dashboard/keystore"><Screen6 /></Route>
                            <Route path="/dashboard/secret-key"><Screen7 /></Route>
                            <Route path="/dashboard/watch-only"><Screen8 /></Route>
                            <Route path="/dashboard/create-account"><Screen9 /></Route>
                            <Route path="/dashboard/profile"><Screen10 /></Route>
                            <Route path="/dashboard/market"><Screen11 /></Route>
                            <Route><Screen1 /></Route>
                        </Switch>
                    </Flex>
                    <Nav />
				</ScrollToTop>
			</Router>
        </Flex>
    )
}