import { Flex, Text } from "@chakra-ui/react";

export default function Privacy() {
    return (
        <Flex direction="column" w="1000%">
            <Text textAlign="center" mb="5">Ellipal Limited service agreement</Text>

            <Text mb="4">
            In order to use Ellipal Limited's services, you should read and abide by the Ellipal Limited Service Agreement (referred to "this Agreement"). Please be sure to read carefully and fully understand the terms of the articles, especially the terms of exemption or limitation of liability, jurisdiction and applicable terms of the law, as well as a separate agreement for the opening or use of a service. Restrictions and disclaimers may be bold or underlined to alert you to focus. Unless you have read and accepted all the terms of this agreement, you do not have the right to use the services provided by Ellipal Limited. Your use of Ellipal Limited's services is deemed to have been read and agreed to be bound by the above agreement.</Text>

            <Text mb="4">
            .If you are under the age of 18, please read this agreement accompanied by a legal guardian and pay special attention to the terms of use for minors.</Text>
            <Text mb="4">
            1.The scope of the agreement</Text>

            <Text mb="4">
            1.1This Agreement is an agreement between you and Ellipal Limited regarding the use of Ellipal Limited related services by users. “Ellipal Limited” means the operational associated unit of Ellipal Limited and its related services. "User" means the user who uses the Ellipal Limited related service and is more commonly referred to as "you" in this Agreement.</Text>



            <Text mb="4">
            1.2 The services under this Agreement refer to the products and services provided by Ellipal Limited to users (hereinafter referred to as "the Service").</Text>


            <Text mb="4">
            1.3 The content of this agreement also includes the Ellipal Limited Privacy Policy. Once the above content is officially released, it is an integral part of this agreement and you should also abide by it. Your acceptance of any of the aforementioned business rules and separate agreements is deemed to be your acceptance of this Agreement.</Text>


            <Text mb="4">
            2. The definition

            2.1 Ellipal Limited: refers to the encrypted digital currency wallet developed by the company, including hardware cold wallet and Ellipal Limited APP hot wallet.

            2.2 Create or import an account: Refers to the process of creating, importing, or connecting to a cold account using Ellipal Limited after you accept this agreement.

            2.3 Account Password: Refers to the password you decide during the creation of the Ellipal Limited wallet, which will be used to encrypt and protect your private key. With Ellipal Limited as a decentralized application, the wallet password is not stored on your mobile device or the company's server. Once you lose or forget your account password, you need to reset your account password with a private key or mnemonic.

            2.4 Information prompt: The information prompt content involved in the Ellipal Limited software operation interface, it is recommended that the user follow the relevant steps.

            2.5 Private key: It consists of 256-bit random characters, which is the core of the user's own and use of digital tokens.

            2.6 Public key: Generated by the private key by means of cryptographic principles, and used to generate the blockchain digital wallet account address, the account address is the public collection address.

            2.7 Mnemonic: consists of 12 ordered words generated by a random algorithm. It is an easy-to-record expression of the private key, which is convenient for users to backup and keep.

            2.8 Keystore: is a file format in which the private key or mnemonic is encrypted and saved by the account password set by the user. It is only stored in your mobile device and will not be synchronized to the company's server.</Text>

            <Text mb="4">

            2.9 Digital Token: Refers to the types of digital tokens currently supported by Ellipal Limited, including but not limited to BTC, ETH, etc.

            2.10 Message Center: Refers to the inbox where users can receive information or notifications from the company.

            2.11 Personal Information: Refers to electronic or other records of information that can be used alone or in combination with other information to identify a user's personal identity, including but not limited to the name, date of birth, identity card number, personal biometric information, and address of a natural person. , phone number, bank card number, email address, wallet address, mobile device information, operation record, transaction record, etc., but does not include the user's wallet password, private key, mnemonic, Keystore.

            2.12 Smart Contract: An Ethereum-based intelligent contract designed to disseminate, validate, or enforce contracts in an information-based manner.

            2.13 BTC: refers to Bitcoin. The concept of Bitcoin was originally proposed by Nakamoto in 2009. According to the idea of ​​Nakamoto, the open source software was designed and the P2P network was constructed. Bitcoin is a digital currency in the form of P2P. Peer-to-peer transmission means a decentralized payment system.

            2.14 ETH: Refers to the Ethereum, which is an encrypted digital token associated with the Ethereum blockchain. For the avoidance of doubt, it does not include the Ethereum Classic.</Text>


            <Text mb="4">
            3. Ellipal Limited services (collectively referred to as "services")

            3.1 Create and restore identity and connect cold wallets. You can use the “Create Identity”, “Resume Identity”, “Connect Cold Wallet” buttons provided by Ellipal Limited to create and restore identity and connect cold wallets, and manage multi-chain wallets in one identity.

            3.2 Import the wallet. For digital tokens supported by Ellipal Limited, you can use Ellipal Limited to generate new wallets or import compatible wallets generated by other wallet tools in the relevant blockchain system.

            3.3 Connect the cold wallet. You can use Ellipal Limited's hardware cold wallet to protect your account security.

            3.4 Transfers and collections. You can use Ellipal Limited's transfer and collection functions to manage digital tokens, that is, use the private key for electronic signature and modify the ledger of the relevant blockchain. Transfer means that the payer uses the ENS or blockchain address of the payee for the transfer, which involves a valid record of the transaction in the distributed ledger of the relevant blockchain system (instead of on Ellipal Limited Actual delivery or transfer of digital tokens).

            3.5 Quotes view. You can use Ellipal Limited to check the digital token exchange rates offered by third parties. Ellipal Limited captures the corresponding digital token exchange rate information and displays the "quotations" section in Ellipal Limited.

            3.6 Coin list management. Users can add/close the display of a coin/token that supported by ELLIPAL wallet.

            3.7 Flash. A digital token exchange between users that can occur and complete on ELLIPAL using Ellipal Limited as an interface tool to help users interact with third-party smart contracts and display the corresponding results exchanged between the users.

            3.8 Transaction history. We will copy some of your transaction records through the blockchain system. However, the user should take the latest transaction record of the blockchain system as the standard.

            3.9 Other services that the company deems necessary.</Text>
            <Text mb="4">

            4. User personal information protection

            4.1 Protecting user personal information is a basic principle of Ellipal Limited. Ellipal Limited will collect, use, store and share your personal information in accordance with this Agreement and the Privacy Policy. The content of the personal information protection provisions of this Agreement conflicts with the above-mentioned Privacy Policy, and the contents of this Agreement are not clearly defined in the personal information protection content, and the contents of the Privacy Policy shall prevail.

            4.2 You may need to fill in the necessary information during the process of using the service.

            4.3 Under normal circumstances, you can browse and modify your own information at any time, but for security reasons, you may not be able to modify the initial registration information and other verification information provided at the time of registration.

            4.4 Ellipal Limited will use a variety of security technologies and procedures to establish a sound management system to protect your personal information from unauthorized access, use or disclosure.

            4.5 Ellipal Limited will not transfer or disclose your personal information to any non-affiliated third parties unless:
            (1) Relevant laws and regulations or requirements of courts and government agencies;
            (2) transfer to complete a merger, division, acquisition or transfer of assets; or
            (3) Required to provide the services you request.

            4.6 Ellipal Limited attaches great importance to the protection of personal information of minors. If you are a minor under the age of 18, you must obtain prior written consent from your parent or legal guardian (hereinafter referred to as the "Guardian") before using the Ellipal Limited service.</Text>

            <Text mb="4">

            5. The way to use the service

            5.1 Unless you agree otherwise with Ellipal Limited, you agree that this service is for your personal, non-commercial use only.

            5.2 You should use the Service by way of Ellipal Limited's offer or endorsement. Your rights under the terms of this agreement are not transferable.

            5.3 You may not interfere, destroy, modify or otherwise affect the services under this Agreement using plug-ins or third-party tools not authorized by Ellipal Limited.</Text>

            <Text mb="4">

            6. Providing services according to the status quo
            You understand and agree that Ellipal Limited's services are provided in accordance with the current state of the art and conditions. Ellipal Limited will do its utmost to provide services to you to ensure the continuity and security of services; however, Ellipal Limited cannot anticipate and prevent legal, technical and other risks at any time, including but not limited to force majeure, viruses, Trojans, hacker attacks, system instability, Service interruptions, data loss, and other losses and risks that may result from third party services, government actions, etc.</Text>

            <Text mb="4">

            7. Products or Services Provided by Third Parties
            When you use products or services provided by third parties on the Ellipal Limited platform, in addition to complying with the terms of this agreement, you should also abide by the third party user agreement. Ellipal Limited and third parties are each liable for possible disputes within the scope of legal provisions and agreements.</Text>

            <Text mb="4">

            8. software-based services

            If Ellipal Limited provides services to you relying on the Software, you should also abide by the following conventions:

            8.1 You may need to download software during the use of this service. For these software, Ellipal Limited gives you a personal, non-transferable and non-exclusive license. You may use the software only for the purpose of accessing or using the service.

            8.2 In order to improve the user experience, ensure the security of the service and the consistency of product functions, Ellipal Limited may update the software. You should update the relevant software to the latest version, otherwise ELLIPLAL does not guarantee its normal use.

            8.3 Ellipal Limited may develop different software versions for different terminal devices. You should choose to download the appropriate version to install according to the actual situation. You can obtain the software directly from Ellipal Limited's website or obtain it from a third party licensed by Ellipal Limited. If you acquire software from a third party that is not authorized by Ellipal Limited or have the same name as the software, Ellipal Limited cannot guarantee that the software can be used normally and will not be responsible for the damages caused to you.</Text>

            <Text mb="4">

            9. illegal users

            9.1 You must abide by laws and regulations when using this service, and you must not use this service to engage in illegal activities including but not limited to:
            (1) Distribute, transmit, disseminate, and store content that endangers national security and unification, undermines social stability, violates public order, good practices, insults, slander, obscenity, violence, and any violation of national laws and regulations;
            (2) the release, transmission, dissemination, storage of content infringing other people's intellectual property rights, commercial secrets, etc.;
            (3) maliciously falsifying facts and concealing the truth to mislead or deceive others;
            (4) Post, transmit, transmit advertisement information and spam;
            (5) Act prohibited by other laws and regulations.

            9.2 If you violate the provisions of this article, the relevant state agencies or agencies may institute lawsuits, fines, or other sanctions against you and ask Ellipal Limited for assistance. If damage is caused, you should pay compensation according to law and Ellipal Limited will not bear any responsibility.

            9.3 If you violate any of the provisions of this article and cause damage to any third party, you shall be solely responsible; if Ellipal Limited suffers losses, you shall also compensate.</Text>

            <Text mb="4">

            10. Compliance with local laws and regulations

            10.1 You should abide by local laws and regulations and respect local ethics and customs when using this service. If your actions violate local laws, regulations or moral customs, you should be solely responsible for this.

            10.2 You should avoid Ellipal Limited being involved in political and public events as a result of using the Service, otherwise Ellipal Limited reserves the right to suspend or terminate your services.</Text>

            <Text mb="4">

            11. Force Majeure and Other Exemptions

            11.1 You understand and agree that in the process of using this service, you may encounter risk factors such as force majeure and disrupt the service. Force majeure is an objective event that cannot be foreseen, cannot be overcome, cannot be avoided and has a significant impact on one or both sides, including but not limited to natural disasters such as floods, earthquakes, plague epidemics and storms, and social events such as war, turmoil, government actions, etc. In the above situation, Ellipal Limited will work hard to cooperate with relevant units in the first instance and repair it in a timely manner. However, the damages caused to you by Ellipal Limited shall be exempted from the scope permitted by law.

            11.2 To the extent permitted by law, Ellipal Limited shall not be liable for any interruption or interruption of service caused by:
            (1) Damage caused by computer viruses, Trojans or other malicious programs, hacking attacks;
            (2) The computer software, system, hardware, and communication lines of the user or Ellipal Limited have failed;
            (3) improper user operation;
            (4) Users use this service through non-Ellipal Limited authorization;
            (5) Other circumstances that Ellipal Limited cannot control or reasonably anticipate.

            11.3 You understand and agree that in the process of using this service, you may encounter risks from network information or other user behaviors. Ellipal Limited does not assume responsibility for the authenticity, applicability, legality of any information, nor is it due to infringement. The behavior is responsible for the damage you caused. These risks include, but are not limited to:
            (1) Information from other people that is anonymous or unnamed that contains threats, defamation, offensive, or illegal content;
            (2) The use of the services under this Agreement is subject to misleading, deceptive, or other psychological or physical damages and economic losses caused or likely to be caused by others;
            (3) Other risks due to network information or user behavior.

            11.4 You understand and agree that this service is not designed for certain specific purposes, including but not limited to important areas such as nuclear facilities, military uses, medical facilities, transportation and communications. Ellipal Limited will not be liable for any personal injury or death, property damage or environmental damage caused by failure of the above software or service.

            11.5 Ellipal Limited has obtained the right to deal with illegal content in accordance with the provisions of this agreement. This right does not constitute the obligations or promises of Ellipal Limited. Ellipal Limited cannot guarantee the timely discovery of illegal acts or deal with them accordingly.

            11.6 In any case, you should not rely on borrowing, asking for a password or other network information concerning your property. If you are involved in the operation of property, please verify the identity of the other party first, and pay attention to Ellipal Limited's tips on preventing fraud.</Text>

            <Text mb="4">

            12. Effectiveness and Change of the Agreement

            12.1 Your use of Ellipal Limited's services is regarded as you have read this agreement and is bound by this agreement.

            12.2 Ellipal Limited reserves the right to modify the terms of this agreement when necessary. You can check the latest version of the terms of the agreement on the relevant services page.

            12.3 After the terms of this Agreement are changed, if you continue to use the software or services provided by Ellipal Limited, you are deemed to have accepted the modified agreement. If you do not accept the modified agreement, you should stop using the software or services provided by Ellipal Limited.</Text>


            <Text mb="4">

            13. Service Change, Interruption, Termination

            13.1 Ellipal Limited may make changes to the service content and may interrupt, suspend, or terminate the service.

            13.2 You understand and agree that Ellipal Limited has the right to determine its own business strategy. When Ellipal Limited merges, splits, purchases, and transfers assets, Ellipal Limited may transfer the relevant assets under this service to a third party; Ellipal Limited may also transfer part or all of the services under this agreement to a third party for operation or performance after unilaterally notifying you. The specific transferee shall be notified by Ellipal Limited.

            13.3 In the event of any of the following situations, Ellipal Limited reserves the right to terminate or terminate the services provided to you without notice:
            (1) According to the law, you should submit true information, and the personal information provided by you is not true, or is inconsistent with the information at the time of registration, and fails to provide reasonable proof;
            (2) You violate the relevant laws and regulations or the agreement of this agreement;
            (3) in accordance with legal requirements or the requirements of the competent authority;
            (4) For security reasons or other necessary circumstances.

            13.4 You are responsible for backing up the data stored in this service yourself. If your service is terminated, Ellipal Limited may permanently delete your data from the server, except as otherwise provided by laws and regulations. After the termination of the service, Ellipal Limited is not obliged to return the data to you.</Text>

            <Text mb="4">

            14. Jurisdiction and Application of Law

            14.1 The establishment, entry into force, performance, interpretation and dispute resolution of this agreement shall apply to the laws of the People's Republic of China (excluding the conflict of laws).

            14.2 If there is any dispute or dispute between you and Ellipal Limited, it should be settled through friendly negotiation. If you fail to negotiate, you agree to submit the dispute or dispute to the people's court that has jurisdiction over the place where the agreement is signed.

            14.3 The headings of all articles of this Agreement are for convenience of reading only and have no practical meaning in themselves. They cannot be used as the basis for interpretation of the meaning of this Agreement.

            14.4 The terms of this agreement are partially invalid or unenforceable for any reason. The remaining terms are still valid and binding on both parties.</Text>

            <Text mb="4">

            15. Minors' Terms of Use

            If the user is under the age of 18, he or she is a minor and should read and use the service under the guardianship and guidance of the guardian.</Text>

            <Text mb="4">

            16. Others

            If you have comments or suggestions about this agreement or this service, you can contact Ellipal Limited customer service department and we will give you necessary help.

                            Ellipal Limited Privacy Policy

            Ellipal Limited (hereinafter referred to as "we") values ​​users' privacy and personal information protection very seriously. When you use our capacity brand or service, we may collect and use your relevant information. We hope to use the "Ellipal Limited Privacy Policy" ("Privacy Policy") to explain to you how we collect, use, store, share and transfer this information when you use our products or services.
            This privacy policy is closely related to your use of the Ellipal Limited service and the various business functions included in the service (collectively referred to as "our products or services"). We hope that you read and confirm it carefully before using our products and/or services. You have fully understood the content of this policy and allow you to make the choices that you see fit in accordance with the guidelines of this Privacy Policy. Your continued use of our products or services after you use or after we update this Privacy Policy (we will promptly update you) means that you agree to the contents of this Privacy Policy (including updated versions) and agree to our compliance with this Privacy Policy Collect, use, save and share your relevant information.</Text>

            <Text mb="4">

            1 The information we collect

            When we provide our services, we simply collect information that is publicly available on the chain and present it to you.
            When you use the service, we will collect the following information:

            1. We will collect personal information such as your transfer device, operation records, transaction records, and account addresses.

            2. To meet your service needs, we will collect your name, cell phone number, email address and other information.

            3. Please note: for security, ELLIPAL does not store your account password, helper, private key and Keystore information in the ELLIPAL. We do not provide the function of retrieving account password, mnemonics, private key and Keystore. 

            2 How do we use information

            1. We may use the information collected during the process of providing services to you for the following purposes:
            (1) To provide you with products or services;
            (2) used for authentication, customer service, security, fraud monitoring, archiving and backup purposes when we provide services.
            (3) to ensure the safety of the products and services we provide to you;
            (4) To send you important notices in a timely manner, such as software updates, service agreements, and changes to this policy;
            (5) inform you of our products, services and activities;
            (6) Perform user behavior analysis by tracking user usage of the application.

            2. You are fully aware that in the following situations, we do not require your authorization to collect and use personal information:
            (1) related to national security and national defense security;
            (2) related to public safety, public health, and major public interests;
            (3) related to criminal investigation, prosecution, trial and execution of judgments;
            (4) The collected personal information is the subject of personal information that is publicly disclosed to the public;
            (5) Your personal information collected from legally disclosed information, such as legitimate news reports, government information disclosure, etc.;
            (6) It is necessary to maintain the safe and stable operation of the provided products and/or services, such as the discovery and disposal of service failures;
            (7) Other circumstances stipulated by laws and regulations.</Text>

            <Text mb="4">

            3 How do you access and control your personal information

            1. You may access, modify and delete your account information and other personal information provided during the use of our services. You may also contact us in accordance with the notice guidelines. The scope and manner in which you access, modify, and delete personal information will depend on the specific services you use.

            2. We will collect and use your information for the purpose of implementing the functions of our products or services as described in this policy. If you find that we have violated the laws, administrative regulations or the two parties' agreement to collect and use your personal information, you can ask us to delete it. If you find that your personal information that we collect and store is wrong, you can also ask us to correct it. Please contact us through the contact details listed in this policy.

            3. When you access, modify and delete relevant information, we may ask you to authenticate to protect your account.

            4. Please understand that due to technical limitations, legal or regulatory requirements, we may not be able to meet all your requirements and we will respond to your request within a reasonable period of time.

            4 We may share information

            We strictly restrict the sharing of information in accordance with laws and regulations, such as:
            1. We will use the information we collect for big data analysis. For example, we use the information we collect to analyze urban thermograms or industry insight reports that do not contain any personal information. We may disclose and share with our partners information that is processed without post-identification content to understand how users use our services or to inform the public about the overall usage trends of our services.</Text>

            <Text mb="4">

            2. We may disclose your personal information for the following purposes:
            (1) comply with applicable laws and regulations and other relevant regulations;
            (2) Compliance with court judgments, rulings or other legal procedures;
            (3) comply with the requirements of the relevant government agencies or other statutory organizations;
            (4) We have reason to believe that we need to comply with the relevant laws and regulations;
            (5) Reasonable and necessary use for the protection of the personal and property safety or other legal rights of our customers, us or our affiliates, other users or employees for the purpose of implementing relevant service agreements or this policy and safeguarding the public interest.

            5 We may be like your message

            1. Information push

            We may send you emails, text messages, news or push notifications when you use our services. You can choose to unsubscribe on your device by following our tips.</Text>


            <Text mb="4">

            2. Announcement related to the service

            We may issue service-related announcements to you when necessary (for example, when a service is suspended due to system maintenance). You may not be able to cancel these announcements that are related to the service and that are not advertising.

            6 How do we protect your personal information?

            We value personal information security and take all reasonable and practicable steps to protect your personal information:

            1. We strictly abide by laws and regulations to protect users' account information.

            2. We will use various security measures to ensure the security of information within a reasonable level of security. For example, we use encryption, anonymization, and other means to protect your personal information.

            3. We establish a special management system, processes and organizations to ensure information security. For example, we strictly limit the range of people accessing information and require them to comply with confidentiality obligations and conduct reviews.

            4. In the event of a security incident such as personal information disclosure, we will initiate an emergency plan to prevent the expansion of security incidents and inform you by push notifications, announcements, etc.</Text>


            <Text mb="4">

            7 Protection for minors

            1. Ellipal Limited attaches great importance to the protection of minors' personal information. If you are a minor under the age of 18, you should obtain prior written consent from your parent or legal guardian before using our products and / or services. Ellipal Limited protects the personal information of minors in accordance with the relevant laws and regulations of the country.

            2. For the collection of personal information of a minor with the consent of a parent or legal guardian, we will only use or publicly disclose this information if it is permitted by law, with the explicit consent of the parent or guardian or the protection of the minor.

            3. If we find that we have collected personal information about a minor without the prior consent of a verifiable parent or legal guardian, we will try to delete the data as soon as possible.</Text>

            <Text mb="4">

            8 The scope of application

            This policy applies to all of our services. However, some services have their own specific privacy guidelines/statements that more specifically describe how we process your information in the service. In the event of any inconsistency between this policy and the privacy guidelines/claims for a particular service, please refer to that particular privacy guide/declaration.
            Please note that this policy does not apply to services provided by other companies or individuals.
            Your use of such third party services is subject to its Privacy Policy (not this Policy) and you will need to read the policies carefully.</Text>

            <Text mb="4">

            9 Contact us

            If you have questions about this policy or other related matters, please contact us at https://www.Ellipal.com. You can also fill in the relevant information according to the guidelines we provide and send your questions to t.chen@Ellipal.com
            We will review the issue as soon as possible and respond within 30 days of verifying your user status.</Text>
            <Text>

            10 Change

            We may revise this policy in due course. If such changes result in a substantial derogation of your rights under this Policy, we will notify you by prompting you at the prominent location on the page, by sending you an email, etc., before the change takes effect. In such a case, if you continue to use our services, you agree to be bound by the revised policy.</Text>
            <Text>
            Updated: August 4, 2021</Text>
            <br/>
        </Flex>
    )
}