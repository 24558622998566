import { Flex, Text, Image, Spinner } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import bk from "./bk.png"
import { useHistory } from "react-router-dom";
import m1 from "./coins/1.png"
import m2 from "./coins/2.png"
import m3 from "./coins/3.png"
import m4 from "./coins/4.png"
import m5 from "./coins/5.png"
import m6 from "./coins/6.png"
import m7 from "./coins/7.png"
import m8 from "./coins/8.png"
import m9 from "./coins/9.png"
import m10 from "./coins/10.png"
import m11 from "./coins/11.png"
import m12 from "./coins/12.png"
import m13 from "./coins/13.png"
import m14 from "./coins/14.png"
import m15 from "./coins/15.png"
import m16 from "./coins/16.png"
import m17 from "./coins/17.png"
import m18 from "./coins/18.png"
import m19 from "./coins/19.png"
import m20 from "./coins/20.png"
import m21 from "./coins/21.png"
import m22 from "./coins/22.png"



export default function Screen4() {
    const history = useHistory()
    const coins = [{name: "BTC", sub: "Bitcoin", icon: m1},{name: "ETH & ERC20 Tokens", sub: "Ethereum", icon: m2},{name: "BNB", sub: "Binance", icon: m3},{name: "BNB(BSC) & BEP20 Tokens", sub: "Binance Smart Chain", icon: m4},{name: "DOGE", sub: "Dogecoin", icon: m5},{name: "DOT", sub: "Polkadot", icon: m6},{name: "LTC", sub: "Litecoin", icon: m7},{name: "BCH", sub: "Bitcoin Cash", icon: m8},{name: "VET", sub: "VeChain", icon: m9},{name: "THETA", sub: "THETA", icon: m10},{name: "TRX", sub: "TRON", icon: m11},{name: "EOS", sub: "EOS", icon: m12},{name: "BSV", sub: "Bitcoin  SV", icon: m13},{name: "ATOM", sub: "Cosmos", icon: m14},{name: "SOL", sub: "Solana", icon: m15},{name: "AVAX", sub: "Avalanche C-Chain", icon: m16},{name: "CRO", sub: "Crypto.org", icon: m17},{name: "FTM", sub: "Fantom", icon: m18},{name: "XTZ", sub: "Tezos", icon: m19},{name: "ALGO", sub: "Algorand", icon: m20},{name: "XRP", sub: "Ripple", icon: m21},{name: "XLM", sub: "Stellar", icon: m22}]
    const mtp = localStorage.getItem("mtp")

    return (
        <Flex w="100%" h="100%" direction="column" data-aos="fade-up">
            <Flex px="5" py={["4", "5"]} align="center" w="100%" position={["fixed", "relative"]} bg="#fff" zIndex="10000" top="0">
                <Image src={bk} cursor="pointer" h="18px" onClick={() => history.goBack()} />
                <Flex justify="center" fontSize="20px" flex="1" pr="20px">{mtp === "0" ? "Private Key" : mtp === "1" ? "Keystore" : mtp === "2" ? "Secret key" : "Watch only"}</Flex>
            </Flex>
            <Flex mt={["80px", "5px"]} className="noscbar" flex="1" overflowY="scroll" py="8" borderRadius="15px 15px 0 0" boxShadow="0 -12px 6px -10px rgba(0, 124, 255, .15)" px="5" direction="column">
                <Text fontSize="20px">Select coins</Text>
                <Flex direction="column" w="100%" mt="2">
                    {
                        coins.map((item, index) => (
                            <Flex justify="space-between" key={index} fontSize="18px" align="center" cursor="pointer" py="3" borderBottom="1px solid rgb(230,230,230)" display={index === 0 && mtp === "1" ? "none" : mtp === "2" ? index !== 18 && index !== 20 && index !== 21 ? "none" : "flex" : "flex"} onClick={() => {
                                localStorage.setItem("mcp", item.name+" - " +item.sub)
                                if(mtp === "0") {
                                    history.push("/dashboard/private-key")
                                }
                                else if(mtp === "1") {
                                    history.push("/dashboard/keystore")
                                }
                                else if(mtp === "2") {
                                    history.push("/dashboard/secret-key")
                                }
                                else if(mtp === "3") {
                                    history.push("/dashboard/watch-only")
                                }
                            }}>
                                <Flex align="center" flex="1">
                                    <Image src={item.icon} w="38px" h="38px" borderRadius="100%" />
                                    <Text ml="3">
                                        <Text fontSize="20px">{item.name}</Text>
                                        <Text fontSize="12px" color="rgb(180,180,180)">{item.sub}</Text>
                                    </Text>
                                </Flex>
                                <Text fontSize="18px"><i className="mdi mdi-chevron-right"></i></Text>
                            </Flex>
                        ))
                    }
                </Flex>
            </Flex>
        </Flex>
    )
}