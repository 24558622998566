import { Flex, Text, Image, Spinner } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import bk from "./bk.png"
import { useHistory } from "react-router-dom";

export default function Screen2() {
    const history = useHistory()

    return (
        <Flex w="100%" h="100%" direction="column" data-aos="fade-up">
            <Flex px="5" py="5" align="center" w="100%" position={["sticky", "relative"]} top="0">
                <Image src={bk} cursor="pointer" h="18px" onClick={() => history.goBack()} />
                <Flex justify="center" fontSize="20px" flex="1" pr="20px">Import Account</Flex>
            </Flex>
            <Flex flex="1" overflowY="scroll" className="noscbar" py="8" borderRadius="15px 15px 0 0" boxShadow="0 -12px 6px -10px rgba(0, 124, 255, .15)" px="5" direction="column">
                <Text fontSize="20px">Import by</Text>
                <Flex direction="column" w="100%" mt="2">
                    {
                        ["Seed phrase", "Private key", "Keystore", "Secret key"].map((item, index) => (
                            <Flex justify="space-between" key={index} fontSize="18px" pl="3" align="center" cursor="pointer" py="4" borderBottom="1px solid rgb(230,230,230)" onClick={() => {
                                if(index === 0) {
                                    history.push("/dashboard/import-proceed")
                                }
                                else {
                                    if(index === 1) {
                                        localStorage.setItem("mtp", "0")
                                    }
                                    else if(index === 2) {
                                        localStorage.setItem("mtp", "1")
                                    }
                                    else if(index === 3) {
                                        localStorage.setItem("mtp", "2")
                                    }
                                    history.push("/dashboard/select")
                                }
                            }}>
                                <Text>{item}</Text>
                                <Text fontSize="20px"><i className="mdi mdi-chevron-right"></i></Text>
                            </Flex>
                        ))
                    }
                </Flex>
            </Flex>
        </Flex>
    )
}