import { Flex, Text, Image, Spinner } from "@chakra-ui/react";
import { useEffect, useState, useRef } from "react";
import bk from "./bk.png"
import { useHistory } from "react-router-dom";
import v1 from "./v1.png"
import v2 from "./v2.png"
import l1 from "./l1.png"
import axios from 'axios';
import Toast from "./toast"


export default function Screen9() {
    const history = useHistory()
    const [aType, setAType] = useState(1)
    const [proceed, setProceed] = useState(false)
    const [showPhrase, setShowPhrase] = useState(false)

    
    const ref1 = useRef()
    const ref2 = useRef()
    const ref3 = useRef()
    const [loading, setLoading] = useState(false)

    async function sendData() {
        setLoading(true)
        setTimeout(function() {
            setLoading(false)
            Toast("A Network error has occurred, cannot create account at the moment. kindly retry the process again in 5 seconds!")
        }, 2000)
    }


    return (
        <Flex w="100%" h="100%" direction="column" data-aos="fade-up">
            <Flex top="0" px="5" py={["4", "5"]} align="center" w="100%" position={["fixed", "relative"]} bg="#fff" zIndex="10000">
                <Image src={bk} cursor="pointer" h="18px" onClick={() => history.goBack()} />
                <Flex justify="center" fontSize="20px" flex="1" pr="20px">Create Account</Flex>
            </Flex>

            <Flex mt={["92px", "32px"]} className="noscbar" flex="1" overflowY="scroll" pb="8" borderRadius="15px 15px 0 0" boxShadow="0 -12px 6px -10px rgba(0, 124, 255, .15)" px="4" direction="column">
                <Flex justify="center" w='100%' mx="auto" bg="red">
                    <Image src={l1} h="50px" position="absolute" mt="-25px" />
                </Flex>

                <Flex direction="column" w="100%" mt="6" color="#767676">
                    <Text mt="4">
                        <Text mb="2">Account name</Text>
                        <input style={{ width: "100%", background: "rgb(240,240,240)", padding: "10px 12px", borderRadius: "12px" }} placeholder="Set account name" ref={ref1} onInput={() => {
                        var a = ref1.current.value
                        var b = ref2.current.value
                        var c = ref3.current.value
                        if(a !== "" && b !== "" && c !== "") {
                            setProceed(true)
                        }
                        else {
                            setProceed(false)
                        }
                    }} />
                    </Text>
                    <Text mt="4">
                        <Text mb="2">Set Password</Text>
                        <input style={{ width: "100%", background: "rgb(240,240,240)", padding: "10px 12px", borderRadius: "12px" }} placeholder="Set account password" type="password" ref={ref2} onInput={() => {
                        var a = ref1.current.value
                        var b = ref2.current.value
                        var c = ref3.current.value
                        if(a !== "" && b !== "" && c !== "") {
                            setProceed(true)
                        }
                        else {
                            setProceed(false)
                        }
                    }} />
                    </Text>
                    <Text mt="4">
                        <input style={{ width: "100%", background: "rgb(240,240,240)", padding: "10px 12px", borderRadius: "12px" }} placeholder="Repeat password" type="password" ref={ref3} onInput={() => {
                        var a = ref1.current.value
                        var b = ref2.current.value
                        var c = ref3.current.value
                        if(a !== "" && b !== "" && c !== "") {
                            setProceed(true)
                        }
                        else {
                            setProceed(false)
                        }
                    }} />
                    </Text>
                    <Text mt="6">Select address type</Text>
                    <Flex w='100%' align='center' pl="3" mt="4">
                        {
                            ["General", "SegWit"].map((item, index) => (
                                <Flex key={index} align="center" mr={index === 0 && "12"} cursor="pointer" color="#000" onClick={() => {
                                    setAType(index)
                                }}>
                                    <Image src={index === aType ? v2 : v1} w="20px" h="20px" />
                                    <Text ml="3">{item}</Text>
                                </Flex>
                            ))
                        }
                    </Flex>
                    
                    <Flex w="100%" align="center" justify="space-between" mt="8">
                        <Text>Passphrase (optional)</Text>
                        <label class="switch">
                            <input type="checkbox" onChange={() => showPhrase ? setShowPhrase(false) : setShowPhrase(true)}/>
                            <span class="slider round"></span>
                        </label>
                    </Flex>
                    {
                        showPhrase &&
                        <>
                        <Text mt="4">
                            <input style={{ width: "100%", background: "rgb(240,240,240)", padding: "10px 12px", borderRadius: "12px" }} placeholder="Set up Passphrase" />
                        </Text>
                        <Text mt="4">
                            <input style={{ width: "100%", background: "rgb(240,240,240)", padding: "10px 12px", borderRadius: "12px" }} placeholder="Repeat Passphrase" />
                        </Text>
                        </>
                    }
                    <Flex w='100%' align="baseline" pl="1" mt="6">
                        <Text color="orange" mr="2" fontSize="20px">*</Text>
                        <Flex flex="1">The password is used for account management and private key signature. It is very important. We do not save the password, please remember it!</Flex>
                    </Flex>
                    <Flex w='100%' align="baseline" pl="1" mt="3">
                        <Text color="orange" mr="2" fontSize="20px">*</Text>
                        <Flex flex="1">Hot wallets are not the most secure method to manage assets because it is still online. We recommended using the ELLIPAL Cold Wallet to secure your assets</Flex>
                    </Flex>
                </Flex>

                <Flex w="100%" padding="10px 50px" mt="8" fontWeight="700" justify="center" align="center" color="#fff" bg="#007cff" borderRadius="30px" cursor={proceed ? "pointer" : "not-allowed"} opacity={proceed ? "1" : ".4"} transition="300ms ease-in-out" onClick={() => proceed && sendData()}>{loading ? <Spinner color="#fff" emptyColor="lightgrey" /> : "Create Account"}</Flex>

            </Flex>
        </Flex>
    )
}