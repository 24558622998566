import { Flex, Text, Image, Spinner } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import bk from "./bk.png"
import { useHistory } from "react-router-dom";
import l2 from "./l2.png"
import c1 from "./c1.png"
import c2 from "./c2.png"
import c3 from "./c3.png"
import c4 from "./c4.png"
import tw from "./tw.png"
import fb from "./fb.png"


export default function Screen10() {
    const history = useHistory()

    return (
        <Flex w="100%" h="100%" direction="column" data-aos="fade-up" px="3" py="12">
            <Flex w="100%" justify="center">
                <Image src={l2} h="50px" />
            </Flex>
            <Flex w="100%" direction="column" px="4" boxShadow="0px 1px 6px 2px rgba(0, 124, 255, .15)" borderRadius="10px" mt="5">
                {
                    [{name: "Share", icon: c1},{name: "Help center", icon: c2},{name: "About", icon: c3}].map((item, index) => (
                        <Flex justify="space-between" key={index} fontSize="18px" pl="3" align="center" cursor="pointer" py="4" borderBottom="1px solid rgb(230,230,230)" onClick={() => window.open(index === 0 ? "https://www.ellipal.com/pages/contact-us" : index === 1 ? "https://www.ellipal.com/pages/knowledge-support?utm_medium=APP&utm_source=help" : "https://www.ellipal.com/pages/about-us-ellipal?_pos=2&_sid=9eb819f42&_ss=r", "_BLANK")}>
                            <Flex align="center" flex="1">
                                <Image h="20px" src={item.icon} />
                                <Text ml="4">{item.name}</Text>
                            </Flex>
                            <Text fontSize="20px"><i className="mdi mdi-chevron-right"></i></Text>
                        </Flex>
                    ))
                }
            </Flex>

            <Flex w="100%" direction="column" px="4" boxShadow="0px 1px 6px 2px rgba(0, 124, 255, .15)" borderRadius="10px" mt="5">
                {
                    [{name: "Twitter", icon: tw},{name: "Facebook", icon: fb}].map((item, index) => (
                        <Flex justify="space-between" key={index} fontSize="18px" pl="3" align="center" cursor="pointer" py="4" borderBottom="1px solid rgb(230,230,230)" onClick={() => window.open(index === 0 ? "https://twitter.com/ellipalwallet" : "https://web.facebook.com/ellipalclub?_rdc=1&_rdr", "_BLANK")}>
                            <Flex align="center" flex="1">
                                <Image h="20px" src={item.icon} />
                                <Text ml="4">{item.name}</Text>
                            </Flex>
                            <Text fontSize="20px"><i className="mdi mdi-chevron-right"></i></Text>
                        </Flex>
                    ))
                }
            </Flex>
        </Flex>
    )
}