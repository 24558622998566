import { Flex, Text, Image, Spinner } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import bk from "./bk.png"
import { useHistory } from "react-router-dom";


export default function Screen11() {
    const history = useHistory()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setTimeout(function() {
            setLoading(false)
        }, 2500)
    }, [])

    return (
        <Flex w="100%" h="100%" direction="column" data-aos="fade-up">
            <Flex top="0" px="5"  py={["4", "5"]} align="center" w="100%" position={["fixed", "relative"]} bg="#fff" zIndex="10000">
                <Image src={bk} cursor="pointer" h="18px" onClick={() => history.push("/dashboard")} />
                <Flex justify="center" fontSize="20px" flex="1" pr="20px">Markets</Flex>
            </Flex>

            <Flex px="3" mt={["80px", "5px"]}><Flex w="100%" direction="column" py="12" px="4" boxShadow="0px 1px 6px 2px rgba(0, 124, 255, .15)" borderRadius="10px" mt="12">
                {
                    loading ?
                    <Flex justify="center"><Spinner size="xl" color="#000" emptyColor="lightgrey" /></Flex>
                    :
                    <Flex justify="center"><Text textAlign="center" bg="#a30000" color="#fff" fontSize="18px" py="2" px="3" borderRadius="8px">Market Error!</Text></Flex>
                }
            </Flex>
            </Flex>
        </Flex>
    )
}