import { Flex, Text, Image, Spinner } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import e1 from "./e1.png"
import a1 from "./a1.png"
import a2 from "./a2.png"
import { useHistory } from "react-router-dom";


export default function Screen1() {
    const history = useHistory()
    return (
        <Flex w="100%" h="100%" px="5" direction="column" data-aos="fade-up" overflowY="scroll" className="noscbar">
            <Text fontSize="20px" textAlign="center" pt="5">ELLIPAL</Text>
            <Text textAlign="center" fontSize="18px" mt="10" px="2">
                For your asset safety, it is recommended to use ELLIPAL Import Account to manage your assets.
            </Text>
            <Flex w="100%" justify="center" mt="10">
                <Image h="200px" src={e1} />
            </Flex>
            <Flex mt="12" justify="space-between" pb={["auto", "50px"]}>
                {
                    [{name: "Create Account", icon: a1}, {name: "Import Account", icon: a2}].map((item, index) => (
                        <Flex w="46%" borderRadius="8px" boxShadow="0px 1px 6px 2px rgba(0, 124, 255, .15)" py="6" px="4" direction="column" align="center" cursor="pointer" key={index} onClick={() => index === 1 ? history.push("/dashboard/import") : history.push("/dashboard/create-account")}>
                            <Image src={item.icon} h="20px" />
                            <Text mt="2" textAlign="center">{item.name}</Text>
                        </Flex>
                    ))
                }
            </Flex>
        </Flex>
    )
}