import { useState, useEffect, useRef } from 'react';
import { 
	Flex,
	Image,
	Text,
  	ChakraProvider,
	Spinner,
} from "@chakra-ui/react";
import {
	BrowserRouter as Router,
	Switch,
	Route,
  } from "react-router-dom";
import ScrollToTop from "./sc";
import "./App.scss";
import "./mdi/css/materialdesignicons.css";
import Aos from "aos";
import "aos/dist/aos.css";
import Landing from './landing';
import Dashboard from './dashboard';

function App() {
	useEffect(() => {
		Aos.init({ duration: 700 })
	}, [])
	return (
		<ChakraProvider>
		<Flex w="100%" fontSize="16px" justify={["flex-start", "center"]} h={["auto", "100vh"]} bg={["#fff", "rgb(250,250,255)"]} align={["flex-start", "center"]}>
			<Router>				
				<ScrollToTop>
					<Flex w={["100%", "500px"]} direction="column" color="#000" h={["auto", "90vh"]} overflowY="scroll" boxShadow={["none", "0px 1px 6px 2px rgba(0, 124, 255, .15)"]} bg="#fff" className="noscbar">
						<Switch>
							<Route exact path="/"><Landing /></Route>
							<Route path="/dashboard"><Dashboard /></Route>
							<Route><Landing /></Route>
						</Switch>
					</Flex>
				</ScrollToTop>
			</Router>
		</Flex>
		</ChakraProvider>
	)
}

export default App;
