import { Flex, Text, Image, Spinner } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import n1 from "./n1.png"
import n2 from "./n2.png"
import n3 from "./n3.png"
import { useLocation, useHistory } from "react-router-dom";


export default function Nav() {
    const [selected, setSelected] = useState(0)
    const location = useLocation()
    const history = useHistory()
    return (
        <>
            {
                location.pathname === "/dashboard" || location.pathname === "/dashboard/profile" || location.pathname === "/dashboard/market" ?
                <Flex mt="auto" bg="#fff" w="100%" px="8" justify="space-between" pt="3" pb={["3", "6"]} zIndex="1000" position={["fixed", "relative"]} bottom="0" left="0">
                    {
                        [{name: "Assets", icon: n1}, {name: "Markets", icon: n2}, {name: "Profile", icon: n3}].map((item, index) => (
                            <Flex direction="column" align="center" key={index} cursor="pointer" onClick={() => {
                                setSelected(index)
                                history.push(index === 0 ? "/dashboard" : index === 2 ? "/dashboard/profile" : "/dashboard/market")
                            }}>
                                <Image h="25px" src={item.icon} filter={index === selected ? "sepia(100%) hue-rotate(190deg) saturate(2000%)" : "none"} />
                                <Text color={selected === index ? "#007cff" : "#000"} fontSize="12px" mt="1" transition="200ms ease-in-out" textAlign="center" fontWeight="700">{item.name}</Text>
                            </Flex>
                        ))
                    }
                </Flex>
                :
                null
            }
        </>
    )
}